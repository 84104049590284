<template lang="pug">
  .c-benefits
    .ed-grid.m-grid-6
      .m-cols-4(style="margin-top:-3rem")
        .c-history__options
          .c-history__filters
          .e-btn.e-btn--rounded.e-btn--small.e-btn--white.e-btn--secondary.e-btn--icon
            i.icon-rango_fechas_ico
          .e-btn.e-btn--rounded.e-btn--secondary.e-btn--small.e-btn--white Rango de fechas
          select-input.white(
            :elements="filters.points"
            @input="changeCurrentSelect()"
            :placeholder="currentFilters.month"
          )
          select-input.white(
            :elements="filters.points"
            @input="changeCurrentSelect()"
            :placeholder="currentFilters.departament"
          )
          select-input.white(
            :elements="filters.points"
            @input="changeCurrentSelect()"
            :placeholder="currentFilters.institution"
          )
          .c-search(
            style="margin-top:1.5rem"
            v-click-outside="hide"
            @click="toggleSearch = true"
            :class="toggleSearch ? 'active': ''"
          )
            input(
              type="text"
              placeholder="Buscar"
            )
            i.icon-buscar_ico
          select-input.white(
            :elements="filters.points"
            @input="changeCurrentSelect()"
            :placeholder="currentFilters.age"
          )
          select-input.white(
            :elements="filters.points"
            @input="changeCurrentSelect()"
            :placeholder="currentFilters.rankAge"
          )
          select-input.white(
            :elements="filters.points"
            @input="changeCurrentSelect()"
            :placeholder="currentFilters.sessions"
          )
      .m-cols-2
        legend-table.c-history__legend
          ul.primary
            li.connect Finalizado
            li.soon En curso
            li.error Trunco
      .m-cols-5
        patient-table
</template>

<script>
// import component from '@/components/component.vue'

export default {
  components: {
    // component
    patientTable: () => import('@/components/03-organism/psychologistTable/default'),
    legendTable: () => import('@/components/01-atomo/legendTable/default'),
    selectInput: () => import('@/components/02-molecule/formElements/select')
  },
  props: {
    // propierty: { required: false, type: String },
  },

  data () {
    return {
      data: '',
      toggleSearch: false,
      currentOption: 'today',
      position: 'all',
      positionOptions: [
        {
          name: 'Todo',
          value: 'all'
        },
        {
          name: 'Mi gestión',
          value: 'my-gestion'
        }
      ],
      filters: {
        points: [
          {
            name: 'Llam. a usuario',
            value: 1000
          },
          {
            name: 'Llam. a psicólogo',
            value: 2000
          },
          {
            name: 'Sala de emociones',
            value: 3000
          }
        ]
      },
      currentFilters: {
        month: {
          name: 'Mes',
          value: null
        },
        departament: {
          name: 'Departamento',
          value: null
        },
        institution: {
          name: 'Institución',
          value: null
        },
        age: {
          name: 'Edad',
          value: null
        },
        rankAge: {
          name: 'Rango de Edad',
          value: null
        },
        sessions: {
          name: 'Sesiones',
          value: null
        }
      }
    }
  },

  computed: {
    // ...mapState({
    // homeCampaign: state => state.homeCampaign
    // })
  },

  watch: {},
  created () {},
  mounted () {},
  updated () {},
  methods: {
    changeCurrentSelect (e) {
      this.currentFilters.points = e
    },
    hide () {
      this.toggleSearch = false
    },
    changeSwitch (e) {
      this.position = e
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
